import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { TestimonialSection } from 'components/sections'
import { Link, SignupButton, Row, Typography, Container, Section, Faqs, SectionTitle } from "components/ui"
import Image from "components/image"
import SnapshotProviders from "pages/snapshots/_snapshotsProviders";
import StorageProviders from "pages/storage/_storageProviders";

const FeaturesPage = () => (
  <Layout>
    <SEO
      refKey="features"
      title="Website, database, server and bucket backups for all your projects."
      description="Manage website, database, server and bucket backups, while keeping things simple! Stored on your cloud storage. "
    />
    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
              <span className="font-medium">Backups for all your projects, the truly</span><br/><strong>non-boring</strong> way.
            </h1>
            <p className="mt-6">All the features you need to manage website, database, server, and bucket backups.</p>
            <SignupButton className="mt-12" noSubtitle urlAtrr={{ sb_source: "website", sb_term: "features" }} />
          </div>
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row>
          <SectionTitle
            tag="h2"
            title={(<>Your projects deserve backups.<br/>You deserve to worry less.</>)}
            subTitle="SimpleBackups helps you orchestrate all your projects' backups, period."
            align="center"
          />

          <div className="grid md:grid-cols-3 gap-16 text-left mt-20">
            <div>
              <i className="fad fa-smile-plus text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">No-code setup</h3>
              <p className="text-base">Forget about Bash scripts and individual backups. Add your backups, choose your preferred storage, and you’re good to go!</p>
            </div>
            <div>
              <i className="fad fa-couch text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">No maintenance required</h3>
              <p className="text-base">We make sure your backups run. If something needs your attention, we will let you know.</p>
            </div>
            <div>
              <i className="fa-duotone fa-binary-lock text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Top notch security</h3>
              <p className="text-base">Encrypt your backups, store them on your server, nothing is stored outside of your infrastructure.</p>
            </div>
            <div>
              <i className="fa-duotone fa-clock text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Flexible schedule</h3>
              <p className="text-base">
                Timezone support. Super frequent scheduling. Calendar-like scheduling UI.
                <Link to="/features/backup-schedule-and-retention/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-shield-alt text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">360 backups coverage</h3>
              <p className="text-base">Pair server snapshots with server and database backups for a bulletproof backup strategy.</p>
            </div>
            <div>
              <i className="fa-duotone fa-clouds text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Connect your own storage</h3>
              <p className="text-base">Connect any cloud storage and securely store backups off-site  or store them locally.
                <Link to="/features/cloudstorage-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-warehouse text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Included storage</h3>
              <p className="text-base">Store your backups on our dedicated and include SimpleStorage.
                <Link to="/features/cloudstorage-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-handshake text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Grant access to your customers</h3>
              <p className="text-base">Grant access to your customers to the backups of their projects, while being in control.</p>
            </div>
            <div>
              <i className="fa-duotone fa-people text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Invite team members</h3>
              <p className="text-base">
                Share access to anyone from your team.
                <Link to="/features/teams-and-projects/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-chart-pie text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Reports & backup statistics</h3>
              <p className="text-base">Get a weekly report of all your running backups as well as statistics on storage and resource consumption.</p>
            </div>
            <div>
              <i className="fa-duotone fa-alarm-exclamation text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Built-in notifications</h3>
              <p className="text-base">Email, Slack, Discord, PushOver, custom webhook notifications for all your backups.
                <Link to="/features/backup-notifications/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-terminal text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">API & Automation</h3>
              <p className="text-base">Get your backups integrated right into your application using our API, webhooks or backup triggers.
                <Link to="/features/api-and-backup-automation/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="alt">
      <Container>
        <Row>
          <SectionTitle
            tag="h2"
            title={(<>Server backups made easy. Prevent data loss.</>)}
            subTitle="All your files and folders backed up, no maintenance required."
            align="center"
          />

          <div className="grid md:grid-cols-3 gap-16 text-left mt-20">
            <div>
              <i className="fad fa-smile-plus text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">AES-256 Encryption</h3>
              <p className="text-base">Secure your file backups using AES-256 encryption and your own key.
                <Link to="/blog/how-to-encrypt-your-backups-using-simplebackups" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fad fa-couch text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Local & Cloud Storage</h3>
              <p className="text-base">Store your backups locally or connect your preferred cloud storage provider.
                <Link to="/features/cloudstorage-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-ball-pile text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Incremental Backups</h3>
              <p className="text-base">Back up only what matters, and optimize your backup file size by saving only when you’ve made changes.
                <Link to="/features/incremental-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-clock text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Flexible Schedule</h3>
              <p className="text-base">Timezone support. Super frequent scheduling. Calendar-like scheduling UI.
                <Link to="/features/backup-schedule-and-retention/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-shield-alt text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Backup Streaming</h3>
              <p className="text-base">Stream upload file backups and upload them on the fly to save disk space during the backup process and benefit from the quicker chunked upload speed.</p>
            </div>
            <div>
              <i className="fa-duotone fa-link text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Symlink Advanced Support</h3>
              <p className="text-base">In some cases you might want to avoid to back up symlinks, just check this option to get this handled.</p>
            </div>
            <div>
              <i className="fa-duotone fa-search text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Find & Restore</h3>
              <p className="text-base">Easily navigate your server & database backups and restore what you need.
                <Link to="/features/find-and-restore-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fa-duotone fa-shield-keyhole text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">SSH Tunneling</h3>
              <p className="text-base">Built-in native SSH that allows you to set up and connect to your server without other tools.</p>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row id="database-backup">
          <SectionTitle
            tag="h2"
            title="Automate your database backups. Prevent data loss."
            subTitle="MySQL, PostgreSQL, MongoDB, and Redis backups with ease."
            align="center"
          />

          <div className="grid md:grid-cols-3 gap-16 text-left mt-20">
            <div>
              <div className="w-16 h-16"><Image filename="mysql-ico.png" alt="MySQL backups" className="w-16"/></div>
              <h3 className="text-xl font-bold mt-4 mb-1">MySQL database backup</h3>
              <p className="text-base">Create your MySQL backups from the comfort of a real UI.
                We leverage mysqldump to make, optimize, and recover your MySQL backups.
                <Link to="/mysql-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <div className="w-16 h-16"><Image filename="postgresql-ico.png" alt="PostgreSQL backups" className="w-16"/></div>
              <h3 className="text-xl font-bold mt-4 mb-1">PostgreSQL database backup</h3>
              <p className="text-base">Create your PostgreSQL backups from the comfort of a real UI.
                We leverage pg_dump to make, optimize, and recover your PostgreSQL backups.
                <Link to="/postgresql-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <div className="w-16 h-16"><Image filename="mongodb-ico.png" alt="MongoDB backups" className="w-16"/></div>
              <h3 className="text-xl font-bold mt-4 mb-1">MongoDB database backup</h3>
              <p className="text-base">Create your MongoDB backups from the comfort of a real UI.
                We leverage mongodump to make, optimize, and recover your MongoDB backups.
                <Link to="/mongodb-backup/" underline={false} arrow="right" className="mt-2 table">Read more</Link>
              </p>
            </div>
            <div>
              <i className="fad fa-couch text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Backup all databases at once</h3>
              <p className="text-base">Instead of backing up a single database, choose this option to back up all individual databases at once.</p>
            </div>
            <div>
              <i className="fa-duotone fa-ball-pile text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">Uncompressed database backup</h3>
              <p className="text-base">Store database backups uncompressed for a speedy restore. This will allow you to store each database dump as a separate file.</p>
            </div>
            <div>
              <i className="fa-duotone fa-clock text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4 mb-1">PostgreSQL Quick Export</h3>
              <p className="text-base">Improved dump method to export smaller-sized dumps quicker, which could be quickly restored in parallel as well.</p>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="alt">
      <Container>
        <Row>
          <SectionTitle
            tag="h2"
            title={(<>Server and Volume snapshots automated.<br/>You're welcome.</>)}
            subTitle="Cross-provider snapshot solution for people that care"
            align="center"
          />
          <SnapshotProviders></SnapshotProviders>
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h2"
              title={(<>Store your backups on S3-compliant storage.</>)}
              align="center"
            />
          </div>
          <div className="grid md:grid-cols-2 gap-16">
            <div>
                <Typography tag="h3" className="font-bold">Compatible with the best in class</Typography>
                <Typography tag="p" className="mt-2">
                  Connect any of your cloud storage providers account and select what to back up and where to securely store it.<br/>
                  It's really simple, and is <Link href="/blog/how-to-connect-your-storage-to-simplebackups" underline={false} arrow={false}>fully documented</Link>.<br/>
                  If your preferred cloud provider is not in the list <Link underline={false} to="/contact-us/">just let us know</Link>!
                </Typography>
            </div>
            <div>
                <Typography tag="h3" className="font-bold">Included SimpleStorage</Typography>
                <Typography tag="p" className="mt-2">
                  SimpleBackups offers <Link to="/storage/simplestorage/" underline={false} >managed storage</Link> for users who want a storage solution out of the box.<br/>
                  SimpleStorage relies on Amazon AWS infrastructure, it is simple and built-in, no separate account required or credentials to input.
              </Typography>
            </div>
          </div>
          <div className="my-24">
            <StorageProviders></StorageProviders>
          </div>
          <Typography tag="p" className="text-center">
            <span className="font-bold">Store your encrypted backups where you want and be GDPR compliant <span role="img">🇪🇺</span>.</span><br/>
            You have the flexibility of storing backups in your region of choice and stick to your regulations and compliance rules.
          </Typography>
        </Row>
      </Container>
    </Section>

    <TestimonialSection/>

    <Section>
      <Container id="features">
        <Row>
          <div className="col-md-12">
            <SectionTitle
              tag="h2"
              title={(<>Frequently Asked Questions</>)}
              align="center"
            />
            <Faqs ids={['is_secure', 'how_does_it_work', 'use_for_client', 'how_encryption', 'what_is_simplestorage', 'what_is_retention', 'what_os_supported', 'another_question']} />
          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)

export default FeaturesPage
